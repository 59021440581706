





























































import {
  defineComponent, computed, useContext, ref, useFetch,
} from '@nuxtjs/composition-api';

import { useProduct } from '~/modules/catalog/product/composables/useProduct';

export default defineComponent({
  props: {
    productSKU: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { getProductDetails } = useProduct();
    const { app } = useContext();

    const loading = ref(true);
    const product = ref({
      name: '',
      url_key: '',
      price_range: {
        minimum_price: {
          regular_price: {
            value: 0,
          },
        },
      },
      image: {
        url: '',
      },
    });

    const fetchProductBaseData = async () => {
      const filter = {
        filter: {
          sku: {
            eq: props.productSKU,
          },
        },
        configurations: [],
      };
      const result = await getProductDetails(filter);
      product.value = result.items[0] as any;
      loading.value = false;
    };

    useFetch(async () => {
      await fetchProductBaseData();
    });

    const productLink = computed(() => app.localePath(`/${product.value?.url_key}`));

    const productStartingPrice = computed(() => {
      const startingPrice = product.value.price_range.minimum_price.regular_price.value;
      return `From $ ${startingPrice}`;
    });

    return {
      productStartingPrice,
      productLink,
      product,
      loading,
    };
  },
});
