









































































import { defineComponent } from '@nuxtjs/composition-api';
import {
  HOME_PAGE_HERO_ICONS,
  HOME_PAGE_HERO_DEFAULT_ICON,
} from '~/utilities/constants';

import ProductPreview from './ProductPreview.vue';
import PageSection from '../../PageSection.vue';

const DEFAULT_HOME_HERO_SECTION = {
  content: {
    title: 'striking modern art that elevates your space.',
    backgroundImage: {
      src: 'defaults/home-hero-image.png',
      alt: 'Striking modern art that elevates your space.',
      tag: 'Hero image',
    },
    buttonMessage: 'Shop Now',
    messages: [
      {
        title: 'curated collection',
        subtitle:
          'We curate striking, statement wall art for you, saving you time and effort.',
        theme: 'decoration',
      },
      {
        title: 'sustainable',
        subtitle:
          'Our innovative, reusable mount folds small and ships light, minimizing carbon footprint.',
        theme: 'sustainability',
      },
      {
        title: 'keep your arms',
        subtitle:
          'Wall-to-wall, museum-grade modern art at an accessible price?  Wow.',
        theme: 'pricing',
      },
    ],
    productSKU: 'MSQ20116',
  },
};
export default defineComponent({
  components: { PageSection, ProductPreview },
  props: {
    cmsSection: {
      type: Object,
      default: () => DEFAULT_HOME_HERO_SECTION,
    },
  },
  setup(props, { emit }) {
    const section = props.cmsSection || DEFAULT_HOME_HERO_SECTION;
    return {
      emit,
      section,
    };
  },
  methods: {
    iconBuilder(item: any) {
      const icon = HOME_PAGE_HERO_ICONS[item.theme] || HOME_PAGE_HERO_DEFAULT_ICON;
      return icon;
    },
  },
});
